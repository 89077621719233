import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { AgentCard, Layout, PartnerCard, SEO, Wide } from '../components';

const AboutPage = ({ data }) => (
  <Layout subtitle="About">
    <SEO
      title="About"
      type="website"
      description="Learn about the history of D. Solis Properties, its agents and the trusted services it uses to help you find a new home or business location."
      pathname="/about/"
    />
    <Wide style={{ height: 150 }}>
      <Img
        fluid={data.banner.childImageSharp.fluid}
        style={{
          position: 'unset',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        imgStyle={{ flexShrink: 0, minWidth: '100%', minHeight: '100%' }}
        alt=""
      />
      <p
        style={{
          position: 'absolute',
          width: '90%',
          maxWidth: 960,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'right',
          color: 'white',
        }}
      >
        D. Solis Properties/
        <span style={{ color: '#8ca36f' }}>{data.pageData.subtitle}</span>
      </p>
    </Wide>
    <h1 style={{ marginTop: 40, textAlign: 'center' }}>
      About <span style={{ color: '#8ca36f' }}>D. Solis Properties</span>
    </h1>
    <div
      style={{
        margin: '0 auto',
        height: 5,
        backgroundColor: 'rgb(140, 163, 111)',
        width: 50,
      }}
    />
    <p
      style={{
        margin: '40px auto',
        textAlign: 'center',
        width: '90vw',
        maxWidth: 960,
      }}
    >
      D. Solis Properties is a full service real estate brokerage that was
      founded in 2014. We handle both commercial and residential services and
      transactions. Our services include representation for tenants, buyers, and
      owners in addition to property management for residential and commerical
      properties.
    </p>
    <h2
      style={{
        textAlign: 'center',
        marginTop: 100,
      }}
    >
      DSP Agents
    </h2>
    <div
      style={{
        margin: '0 auto',
        height: 5,
        backgroundColor: 'rgb(140, 163, 111)',
        width: 50,
      }}
    />
    {data.pageData.agents.map(({ name, bio, img }) => (
      <AgentCard name={name} bio={bio} img={img} key={name} />
    ))}
    {/* <h4
      style={{
        textAlign: 'center',
        margin: '20px auto',
        color: 'rgb(140, 163, 111)',
        fontSize: '1.1em',
      }}
    >
      More Coming Soon!
    </h4> */}
    <h2
      style={{
        textAlign: 'center',
        marginTop: 100,
      }}
    >
      Trusted Services
    </h2>
    <div
      style={{
        margin: '0 auto',
        height: 5,
        backgroundColor: 'rgb(140, 163, 111)',
        width: 50,
      }}
    />
    <PartnerCard />
  </Layout>
);

AboutPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "fortworth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: pageJson(page: { eq: "about" }) {
      subtitle
      agents {
        name
        bio
        img {
          src {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`;
